<template>
  <div v-if="dataPresent">
    <p class="taskListInfo dark-text">{{ $t(`${selectedTaskListItem.description}`) }}</p>
    <div v-if="selectedTaskListItem && selectedTaskListItem.extraData && selectedTaskListItem.extraData.useDynamicFilters" >
      <Form class = "float-left" :key="selectedTaskListItem.name" :name='selectedTaskListItem.name'
      :loadFormData=true
      :isEditing=true
      :sendPayloadInsteadOfSubmit = true
      :submitButtonInRightSide = true
      :showClearBtn = true
      @submitData="handleTasklistUIFilter"/>
    </div>
    <div v-if="patientsLoading && dataPresent">
      <p class="taskListLoading ufmt-lg text-ibm-plex-sans dark-text">
        Loading <font-awesome-icon icon="spinner" class="fa-lg" spin />
      </p>
    </div>
    <v-slide-y-transition v-else>
      <div class="detailsHolder ufpa-sm">
        <div>
          <div class="actionContainer">
            <div
              v-if="!columnLengthIsZero"
              class="left-container ufmt-sm ufmb-sm"
            >
              <Select
                name="columnSelector"
                id="columnSelector"
                label="Select columns to display"
                :is-multi-select="true"
                :allOptions="allColumnOptions"
                :placeholderText="'Select Columns'"
                v-model="selectedColumns"
                @change="columnSelected($event)"
              />
            </div>
            <PageTurner
              @changePageEvent="changePage"
              :pageSize="pageSize"
              :totalItems="totalPatients"
              :currentPage="selectedPage"
              :pageTurnerSize="5"
            ></PageTurner>
          </div>
        </div>
        <div class="patientsTableContainer">
        <Table
            :columns="tableColumns"
            :data="tableData"
            :pageSize="pageSize"
            :searchable="true"
          ></Table>
        </div>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Select from '@/app/shared/components/Select.vue'
import Table from '@/app/shared/components/Table.vue'
import PageTurner from '@/app/shared/components/PageTurner.vue'
import EventBus from '@/app/shared/event-bus.js'
import Form from '@/app/shared/components/Form.vue'

export default {
  name: 'TasklistTabsSection',
  components: {
    Select,
    Table,
    PageTurner,
    Form
  },
  data: function () {
    return {
      allColumnOptions: [],
      selectedColumns: [],
      selectedPage: 1,
      sortBy: 'id',
      sortDesc: false,
      tableColumns: [],
      tableData: [],
      uiFilterPayload: {},
      dataPresent: false
    }
  },
  watch: {
    allcolumns (newValue, oldValue) {
      // eslint-disable-next-line eqeqeq
      if (newValue != oldValue) {
        this.allColumnOptions = newValue.map((item) => {
          return { Key: item.id, Value: item.value }
        })
        if (newValue.length === 0) {
          this.selectedColumns = []
          this.tableColumns = []
        } else {
          this.selectedColumns = newValue.map((item) => {
            return { Key: item.id, Value: item.value }
          })
        }
      }
    },
    tableColumns (newColumns, oldColumns) {
      // eslint-disable-next-line eqeqeq
      if (newColumns != oldColumns) {
        if (newColumns.length === 0) {
          this.updateTableData([])
        } else {
          this.updateTableData(this.patientsData.patients)
        }
      }
    },
    '$route.name': {
      handler: async function (name) {
        this.onTabChange()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    columnLengthIsZero () {
      return this.allcolumns.length === 0
    },
    numberPages () {
      return Math.ceil(this.totalPatients / this.pageSize)
    },

    ...mapState('Tasklist', [
      'taskListsDetails',
      'activeItem',
      'patientsLoading',
      'allcolumns',
      'patientsData',
      'pageSize',
      'totalPatients',
      'inputData',
      'taskListsDetailsLoading',
      'taskListIdInputDataMap',
      'selectedTaskListItem',
      'taskListItemMappedByTasklistId',
      'columnDetailsMappedByValue'
    ])
  },
  methods: {
    ...mapActions('Tasklist', [
      'loadTaskListFunction',
      'loadTaskListDetails',
      'setInputData',
      'setSelectedTasklistItem'
    ]),
    itemClicked (column, item) {
      const { tId } = this.inputData
      const tasklist = this.taskListItemMappedByTasklistId[tId]
      const { relativePath } = tasklist.extraData

      let path
      switch (column) {
        case 'Id':
        case 'Name':
          path = `dashboard/patient/${item.Id}`
          break
        case 'reportingUnitId':
          path = `dashboard/contract/${item.reportingUnitId}/details`
          break
        default:
          return
      }

      const basePath = relativePath ? `${process.env.VUE_APP_BASE_URL}/` : '/'
      window.open(`${basePath}${path}`, '_blank')
    },
    columnSelected (e) {
      if (this.selectedColumns.length === 0) {
        this.tableColumns = []
      } else {
        this.updateTableColumns()
      }
    },
    updateTableColumns () {
      const newTableCloumns = []
      this.selectedColumns.forEach((item) => {
        if (
          Object.prototype.hasOwnProperty.call(
            this.columnDetailsMappedByValue,
            item.Key
          )
        ) {
          const clmOjb = {
            name: this.columnDetailsMappedByValue[item.Key].id,
            key: this.columnDetailsMappedByValue[item.Key].id,
            sortable: this.columnDetailsMappedByValue[item.Key].sortable,
            clickable: true
          }
          newTableCloumns.push(clmOjb)
        }
      })
      this.tableColumns = newTableCloumns
    },
    async changePage (number) {
      if (!this.patientsLoading) {
        this.selectedPage = number
        await this.loadTaskList()
        this.updateTableColumns()
        this.updateTableData()
      }
    },
    updateTableData (data) {
      this.tableData = data
    },
    async loadTaskList () {
      await this.loadTaskListFunction({
        input: this.inputData,
        page: this.selectedPage - 1,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        uiFilterPayload: this.uiFilterPayload
      })
    },
    async loadDetails () {
      await this.loadTaskListFunction({
        input: this.inputData,
        page: 0,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        uiFilterPayload: this.uiFilterPayload

      })
      this.updateTableColumns()
    },
    async onTabChange () {
      this.selectedPage = 1
      this.uiFilterPayload = {}
      const tasklistId = this.$route.params.pathMatch
      await this.setInputData(this.taskListIdInputDataMap[tasklistId])
      await this.setSelectedTasklistItem(
        this.taskListItemMappedByTasklistId[tasklistId]
      )
      await this.loadDetails()
    },

    async handleTasklistUIFilter (data) {
      this.uiFilterPayload = data
      await this.changePage(1)
    }
  },
  async beforeMount () {
    if (!this.selectedTaskListItem.name) {
      const tasklistId = this.$route.params.pathMatch
      await this.loadTaskListDetails(tasklistId)
    }
    this.dataPresent = true
    await this.loadDetails(0)
    EventBus.$on('clickTableEvent', this.itemClicked)
  }
}
</script>

<style lang="scss">
.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#columnSelector .select, #columnSelector .dropdown  {
  width: 125%;
}
</style>
<style lang="scss" scoped>
#search-card {
  font-family: "IBM Plex Sans" !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  letter-spacing: 0.005em !important;
  padding: 2rem !important;
  margin: 10px;
}
.taskListInfo {
  padding-top: 16px;
  padding-left: 16px;
}
</style>
