<template>
  <div id="app">
    <Header />
    <div class="page-body" id="main-page">
    <router-view />
    </div>
  </div>
</template>

<script>
import Header from './shared/components/Header/Header.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'App',
  metaInfo: {
    titleTemplate: (titleChunk) => {
      return titleChunk ? `${titleChunk} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_LOGIN_TITLE
    },
    title: '',
    meta: [{
      property: 'description',
      content: 'A comprehensive, integrated platform for adherence and patient management',
      name: 'description'
    }, {
      property: 'og:title',
      content: process.env.VUE_APP_LOGIN_TITLE,
      name: 'og:title'
    }, {
      property: 'og:description',
      content: 'A comprehensive, integrated platform for adherence and patient management',
      name: 'og:description'
    }, {
      property: 'og:url',
      content: process.env.VUE_APP_SELF_URL,
      name: 'og:url'
    }, {
      property: 'og:type',
      content: 'product',
      name: 'og:type'
    }]
  },
  components: {
    Header
  },
  mounted () {
    if (localStorage.theme) {
      this.setTheme(localStorage.theme)
    }
  },
  methods: {
    setTheme (theme) {
      if (theme !== 'acc-mode') {
        this.toggleDarkMode(theme === 'dark-theme')
      } else {
        this.toggleAccessibilityMode(true)
      }
    },
    ...mapActions(['toggleDarkMode', 'toggleAccessibilityMode'])
  },
  computed: {
    ...mapGetters(['darkModeState', 'accessibilityModeState'])
  },
  watch: {
    darkModeState (val) {
      var userTheme = val ? 'dark-theme' : 'light-theme'
      localStorage.setItem('theme', userTheme)
      document.documentElement.setAttribute('data-theme', userTheme)
    },
    accessibilityModeState (val) {
      var userTheme = 'acc-mode'
      if (!val) {
        userTheme = this.darkModeState ? 'dark-theme' : 'light-theme'
      }
      localStorage.setItem('theme', userTheme)
    }
  }
}
</script>

<style lang="scss">
@import "../scss/utils.scss"; // helpers
@import '~@fontsource/ibm-plex-sans/index.css'; // fonts IBM Plex (not available by default)
body {
  margin: 0;
  padding: 0;
}
html{
  overflow-y: hidden !important;
  overflow-x: auto!important;
}
html *{
  font-family : 'IBM Plex Sans'
}
.table-generic-cursor{
  cursor: auto
}
#app {
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $app-col;
  background: var(--background-bg-color);
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.text-ibm-plex-sans {
  font-family: "IBM Plex Sans", sans-serif;
}

.lessWidth {
  width: 60%;
  padding: 4rem;
}

.toastCancelIconWhite {
  height: 1rem;
  width: 2rem;
  background: url("~@/assets/toastCancelWhite.svg");
  background-repeat: no-repeat;
  padding: 8px 0px !important;
  box-sizing: unset;
}
.toastBtnWhite {
  color: $white !important;
  text-decoration: none !important;
  box-sizing: unset;
}
.toastCancelIconBlack {
  height: 1rem;
  width: 2rem;
  background: url("~@/assets/toastCancelBlack.svg");
  background-repeat: no-repeat;
  padding: 8px 0px !important;
  box-sizing: unset;
}

.vot_status-confirm-icon {
  background: url("~@/assets/vot_check.svg");
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  display: block;
  /* Other styles here */
}
.vot_status-miss-icon {
  background: url("~@/assets/vot_cross.svg");
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  display: block;
  padding-top: 5px;
  /* Other styles here */
}
.view-video-icon {
  background: url("~@/assets/circle-play-solid.svg");
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  display: block;
}

.view-video-icon-skeleton {
  background: url("~@/assets/circle-play-solid-grey.svg");
  background-repeat: no-repeat;
  height: 22px;
  width: 22px;
  margin-left: 2px;
  margin-right: 2px;
  display: block;
}

.no-decoration {
  &:hover {
    text-decoration: unset !important;
    border-bottom: unset !important;
  }
}
.pointer {
  cursor: pointer;
}
.center {
  margin: auto;
  padding: 10px;
  width: 50%;
}

#vot_icons{
  padding: unset !important;
  padding-top: 0.75rem !important;
}

.toastBtnBlack {
  color: $black !important;
  text-decoration: none !important;
  box-sizing: unset;
}
.toastSuccess {
  min-width: 15rem;
  max-width: 30rem !important;
  background: $toast-success-bg !important;
  color: $white !important;
  border-radius: 4px !important;
  padding: 6px 16px !important;
  font-weight: 500 !important;
  box-sizing: unset!important;
}
.toastError {
  min-width: 15rem;
  max-width: 30rem !important;
  background: $error !important;
  color: $white !important;
  border-radius: 4px !important;
  padding: 6px 16px !important;
  font-weight: 500 !important;
  box-sizing: unset!important;
}
.toastWarning {
  min-width: 15rem;
  max-width: 30rem !important;
  background: $warning !important;
  color: $black !important;
  border-radius: 4px !important;
  padding: 6px 16px !important;
  font-weight: 500 !important;
  box-sizing: unset!important;
}
.toastNeutal {
  min-width: 15rem;
  max-width: 30rem !important;
  background: $input-bg !important;
  color: $black !important;
  border-radius: 4px !important;
  padding: 6px 16px !important;
  font-weight: 500 !important;
  box-sizing: unset!important;
}
.label-div {
  width: 100%;
}

.ew-field-group {
  display: flex;
  margin-bottom: 16px !important;
}

.ew-input {
  padding: 11px 16px;
  background: var(--input-bg);
  border: none;
  border: 1px solid var(--input-border);
  // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  line-height: 22px;
  border-radius: 8px;
  width: 100%;
  color: var(--text-primary-color);
  font-size: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.ew-input::placeholder {
  color: $placeholderText;
}

.ew-input:hover {
  border : none;
  border: 1px solid var(--primary-theme);
}

.ew-input:focus {
  border: none;
  outline: none;
  border: 1px solid var(--primary-theme);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.12);
}

.ew-input:disabled {
  background: var(--input-disabled-bg);
  border: none;
}

.ew-field-group-field {
  display: flex;
  width: 100%;
}

.ew-field-label {
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  margin-right: 32px;
  font-family: 'IBM Plex Sans', sans-serif;
  color: var(--tabs-text-color)
}

.ew-field-group-horizontal {
  flex-direction: row;
  align-items: center;
}

.ew-field-group-vertical {
  align-items: stretch;
  flex-direction: column;
  align-self: flex-start;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.flex1 {
  flex: 1;
}

.option-label-weight {
  font-weight: 600;
}

.ew-label-weight {
  color: #A0A3BD;
  font-weight: 500;
}

.flex-justify-right {
  justify-content: right;
}

// fonts
.text-ibm-plex-sans {
  font-family: "IBM Plex Sans", sans-serif;
}

.content {
  padding: 12px;
}
.length {
  width: 30%;
}
.page-body{
  position: fixed;
  margin-top:3.625rem;
  height: calc(100vh - 4rem);
  width: 100%;
}

::-webkit-scrollbar {
  background: var(--light-hover-color-with-dark-hover);
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

::-webkit-scrollbar-button:vertical:start:increment,
::-webkit-scrollbar-button:vertical:end:decrement,
::-webkit-scrollbar-button:horizontal:start:increment,
::-webkit-scrollbar-button:horizontal:end:decrement {
  width: 0;
  height: 0;
}

:root {
  --background-color-primary: #{$white};
  --background-color-primary-light-dark: #{$white};
  --accent-color: #{$accentColor};
  --text-primary-color: #{$text};
  --day-select-color: #{$black};
  --table-header: #{$dark};
  --table-row-color: #{$body};
  --text-dark-background-color: #{$off-white};
  --light-hover-color: #{$veryLightGray};
  --light-hover-color-v2: #{$lightGray};
  --light-hover-color-light-dark: #{$veryLightGray};
  --theme-opacity: 1;
  --light-shadow-color: #{$lightShadowColor};
  --info-box-background: #{$navyBlue5perc};
  --hr-color: #{$veryLightBlue};
  --tabs-text-color: #{$tabsTextColor};
  --highlight-color: rgba($primary,0.05);
  --very-light-shadow: #{$black_alpha_015};
  --primary-color-text: #{$primary};
  --background-bg-color: #{$bg};
  --breadcrumbs-text-color: gray;
  --notes-box-color: #{$off-white};
  --input-bg: #{$white};
  --input-disabled-bg: #{$input-bg};
  --text-loading-color: #{$textLoadingColor};
  --text-label-color: #{$label};
  --gray-color: #{$gray};
  --ghost-white: #{$ghost-white};
  --breadcrumbs-active: #{$primary};
  --text-dark-blue: #{$text};
  --text-table-data: #{$body};
  --place-holder-text: #{$placeholderText};
  --primary-theme: #{$primary};
  --primary-light: #{$primaryLight};
  --sidebar-bg: #{$sidebarBackground};
  --sidebar-hover: #{$sidebarSelectedItemBackground};
  --sidebar-nested: #{$sidebarNestedItemBackground};
  --sidebar-language-selector: #{$sidebar-language-selector};
  --header-selector: #{$header-selector};
  --light-hover-color-with-dark-hover: #{$veryLightGray};
  --input-border: #{$input-border};
  --scrollbar-track: #{$black_alpha_001};
  --scrollbar-thumb: #{$black_alpha_02};
  --scrollbar-thumb-hover: #{$black_alpha_03};
  --table-header-gray: #{$veryLightGray};
  --form-part-color: #{$white};
  --btn-disabled-bg: #{$buttonDisabledbackground};
  --very-light-input-border: #E6E9F1;
  --task-list-hover: #{$tasklistContainerBorder};
  --app-color: #{$app-col};
}

[data-theme="dark-theme"] {
  --background-color-primary: #{$backgroundColorDark};
  --background-color-primary-light-dark: #{$littleDark};
  --accent-color: #{$accentColorDark};
  --text-primary-color: #{$off-white};
  --day-select-color: #{$white};
  --table-header: #{$off-white};
  --table-row-color: #{$off-white};
  --text-dark-background-color: #{$off-white};
  --light-hover-color: #{$littleDark};
  --light-hover-color-v2: #{$littleDark};
  --light-hover-color-light-dark: #{$primaryThemeSecondary};
  --theme-opacity: 0.85;
  --light-shadow-color: #{$lightShadowColorDark};
  --info-box-background: #{$white_alpha_01};
  --hr-color: #{$white_alpha_02};
  --tabs-text-color: #{$off-white};
  --highlight-color: #{$white_alpha_02};
  --very-light-shadow: #{$lightShadowColorDark};
  --primary-color-text: #{$off-white};
  --background-bg-color: #{$bgDark};
  --breadcrumbs-text-color: #{$white};
  --notes-box-color: #{$littleDark};
  --input-bg: #{$littleDark};
  --input-disabled-bg: #{$darkGray};
  --text-loading-color: #{$off-white};
  --text-label-color: #{$off-white};
  --gray-color: #{$darkGray};
  --ghost-white: #{$littleDark};
  --breadcrumbs-active: #{$info};
  --text-dark-blue: #{$white};
  --text-table-data: #{$off-white};
  --place-holder-text: #{$off-white};
  --primary-theme: #{$primaryThemeDark};
  --primary-light: #{$primaryLightDark};
  --sidebar-bg: #{$primaryThemeSecondary};
  --sidebar-hover: #{$primaryLightDark};
  --sidebar-nested: #{$bgDark};
  --sidebar-language-selector: #{$primaryLightDark};
  --header-selector: #{$bgDark};
  --light-hover-color-with-dark-hover: #{$primaryThemeSecondary};
  --input-border: #{$white_alpha_03};
  --scrollbar-track: #{$scrollbar-track-dark};
  --scrollbar-thumb: #{$scrollbar-thumb-dark};
  --scrollbar-thumb-hover: #{$scrollbar-thumb-hover-dark};
  --table-header-gray: #{$primaryThemeSecondary};
  --form-part-color: #{$primaryThemeSecondary};
  --btn-disabled-bg: #{$darkGray};
  --very-light-input-border: #{$white_alpha_03};
  --task-list-hover: #{$primaryLightDark};
  --app-color: #{$white_alpha_08};
}

.dark-text {
  color: var(--text-primary-color);
}

.ew-hr {
  margin: 10px 0 10px 0;
  padding: 0;
  height: 1px;
  border:none;
  background-color: var(--hr-color);
}
.display-flex {
  display: flex;
}
</style>
