<template>
  <div class="table_container">
  <div class="vcard">
    <v-card-title v-if="title || searchable" id="search-card" class="card-title-with-search">
      <span class="dark-text">{{$t(title)}} </span>
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search in the table..."
        single-line
        hide-details
        class="ew-tablesearch-input"
        v-if="searchable"
      ></v-text-field>
    </v-card-title>
  </div>
  <v-data-table
    :items="data"
    :headers="createHeader"
    :search="search"
    :items-per-page="pageSize"
    :calculate-widths="true"
    @update:options="sortEvent"
    hide-default-footer
    class="elevation-1 ew-custom-table">
      <template v-slot:item="{ item }">
        <tr class="custom-table-tr">
          <td v-for="header in createHeader" v-bind:key="item.Id + header.Id" v-on:click="GoToPatient(header.value, header.clickable, item)" > <div v-if="header.value !== 'action'" v-html="item[header.value] || '-'" class="table-generic-cursor" :class="getClassForHeaderName(header.text)"/>
            <div v-if="header.value === 'action'" class="flex-box">
              <Button v-for="action in item.action.tableActions" :key="action.displayKey" :label="action.displayKey" class="table-button" :class="button-active" :disabled="action.disabled" v-on:click="ActionButtonEvent(item, action)">
                {{ $t(action.displayKey) }}
              </Button>
            </div>
          </td>
          <td class="table-action-btn" v-if='addDeleteIcon'><button id="removeRow" v-on:click="DeleteEvent(item)"> <span class="delete-icon"></span></button></td>
        </tr>
      </template>
    </v-data-table>
</div>
</template>
<script>
import EventBus from '@/app/shared/event-bus.js'
import Button from './Button.vue'
export default {
  data () {
    return {
      search: '',
      totalPatients: 0,
      monotonicValue: 1
    }
  },
  emits: ['sortTableEvent'],
  components: {
    Button
  },
  computed: {
    createHeader () {
      var arr = []
      var len = this.columns?.length
      const width = 100 / this.columns?.length + '%'
      for (var i = 0; i < len; i++) {
        arr.push({
          text: this.$t(this.columns[i].name),
          value: this.columns[i].key,
          sortable: this.columns[i].sortable,
          clickable: this.columns[i].clickable,
          width: width,
          Id: this.getIncreasingValue()
        })
      }
      return arr
    }
  },
  props: {
    pageSize: {
      type: Number,
      default: 5
    },
    columns: Array,
    data: Array,
    title: {
      default: '',
      type: String
    },
    searchable: {
      default: false,
      type: Boolean
    },
    colMaxWidth: {
      type: Number,
      default: 0
    },
    addDeleteIcon: {
      type: Boolean,
      default: false
    },
    actionButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getClassForHeaderName (value) {
      let className = ''
      if (value === this.$t('patient_id_web')) { className = 'hyperlink' }
      if (value === this.$t('Id')) { className = 'hyperlink' }
      if (value === this.$t('Name')) { className = 'hyperlink' }
      if (value === this.$t('Reporting Unit Id')) { className = 'hyperlink' }
      return className
    },
    GoToPatient (column, clickable, item) {
      if (clickable) {
        EventBus.$emit('clickTableEvent', column, item)
        this.$emit('clickTableEvent', column, item)
      }
    },
    ActionButtonEvent (item, action) {
      this.$emit('actionButtonEvent', item, action)
    },
    DeleteEvent (item) {
      this.$emit('deleteRowEvent', item)
    },
    sortEvent (options) {
      var sortData
      if (options.sortBy?.length > 0 && options.sortDesc?.length > 0) {
        sortData = {
          sortBy: options.sortBy[0],
          sortDesc: options.sortDesc[0]
        }
      }
      EventBus.$emit('sortTableEvent', sortData)
    },
    getIncreasingValue () {
      this.monotonicValue = this.monotonicValue + 1
      return this.monotonicValue
    }
  },
  mounted () {
    if (this.$props.colMaxWidth === 0) {
      document.documentElement.style.setProperty('--default-column-width', 'unset')
    } else {
      document.documentElement.style.setProperty('--default-column-width', `${this.$props.colMaxWidth}px`)
    }
  }
}
</script>
<style lang = "scss" scoped>
  .table-button {
    display: inline-block;
  cursor: pointer;
  background-color: var(--primary-theme);
  text-transform: uppercase;
  color: white; /* Text color changed for visibility */
  padding: 8px 12px; /* Adjust padding as needed */
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  border: none; /* Removed border */
  border-radius: 12px; /* Applied border-radius for curved edges */
  min-width: 120px;
  text-align: center;
  height: 40px;
  margin: unset !important;
}
  .table_container{
    padding: 2px;
    margin: 0 20px 0 20px;
    background-color: var(--background-color-primary-light-dark);
  }
  .v-input__slot{
    height: 40px;
  }
  .ew-tablesearch-input {
    background-color: var(--background-color-primary-light-dark);
    border: 2px solid var(--input-border) ;
    border-radius: 8px;
    margin-top: 0px;
    padding-top: 0px;
  }
  .v-text-field__slot label{
    position: relative !important;
    font-weight: 100 !important;
    font-size: 21px !important;
    top: -5px !important;
    letter-spacing: 0.0025em !important;
    color: #A0A3BD !important;
  }
  .v-text-field__slot input{
    position: absolute;
    font-weight: 100 !important;
    font-size: 21px;
    top: -28px !important;
    letter-spacing: 0.0025em;
    color: #1E234C;
  }
  .v-data-table{
    border-radius: 8px;
    font-size: 40px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #1E234C;
  }
  .v-card__title{
    position: relative;
    padding-left: 0px !important;
    /* font-size: 40px !important;
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: bold; */
  }
  .hyperlink {
    color: var(--primary-theme) !important;
    font-weight: 500;
    transition: 0.3s;
  }
  .hyperlink:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .table-button {
    display: inline-block;
  cursor: pointer;
  background-color: var(--primary-theme);
  text-transform: uppercase;
  color: white; /* Text color changed for visibility */
  padding: 8px 12px; /* Adjust padding as needed */
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  border: none; /* Removed border */
  border-radius: 12px; /* Applied border-radius for curved edges */
  min-width: 120px;
  text-align: center;
  height: 40px;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-active {
    background-color: var(--primary-theme);
    color: white;
  }
</style>
<style lang='scss'>
html{
  --default-column-width:150px
}

.ew-custom-table thead tr th{
  max-width:var(--default-column-width)
}
.ew-custom-table thead tr th {
    font-family: 'IBM Plex Sans', sans-serif;
    text-align: center !important;
    padding-left: 0px !important;
    padding-right: 16px !important;
    height: 70px !important;
    font-size: 14px !important;
    line-height: 22px !important;
    letter-spacing: 0.0125em !important;
    border-width: 2px !important;
    color: var(--text-dark-blue) !important;
    font-weight: 600 !important;
    background-color: var(--light-hover-color-light-dark);
  }
  .ew-custom-table {
    background-color: var(--light-hover-color-light-dark) !important;
  }
  .ew-custom-table tbody {
    background-color: var(--background-color-primary-light-dark);
  }
  .ew-custom-table tbody tr td {
    font-family: 'IBM Plex Sans', sans-serif;
    text-align: center !important;
    padding-left: 0px !important;
    padding-right: 16px !important;
    font-size: 14px !important;
    line-height: 22px !important;
    border-width: 2px !important;
    letter-spacing: 0.0125em !important;
    color: var(--text-table-data) !important;
    word-wrap: break-word;
    white-space: pre-line;
    min-width: 100px;
  }
  .ew-custom-table tbody tr:hover {
    background-color: var(--light-hover-color-light-dark) !important;
  }
  .ew-custom-table thead tr th:first-child {
    padding-left: 16px !important;
  }
  .ew-custom-table tbody tr td:first-child {
    padding-left: 16px !important;
  }
  .v-input__icon i{
    position: absolute;
    color: #A0A3BD;
  }
  .v-input__prepend-inner {
    margin: 6px 8px 0px 8px !important;
  }
  .v-input__slot::before {
    border: none !important;
  }
  .ew-tablesearch-input input, .ew-tablesearch-input label, .ew-tablesearch-input i{
    color: var(--text-primary-color) !important;
  }
  span.delete-icon {
    background: url("~@/assets/iconDelete.png") no-repeat;
    float: left;
    height: 40px;
    width: 40px;
    margin-top: 8px;
  }
  .card-title-with-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-title-with-search .ew-tablesearch-input {
    max-width: 300px;
  }
</style>
